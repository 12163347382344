/** @jsx jsx */
import { jsx } from "theme-ui"
import { ProdIcons } from "./product-title"
import React from "react"
import { dataStorageKeys, getLocalStorageItem, setLocalStorageItem } from "../util/local-storage-wrapper"
import { nameMap, associatedProductMap, productIconMap } from "../util/product-mappings"
import { ViewChangeVehicle } from "./view-change-vehicle"
import { calculatePercentageSaving, calculateSeparateCosts } from "./carefree-bundle"

const PackageListItem = ({ selectedPackage, onClick, children, packageData, isCarefree = false }) => {
  const { productCode, monthlyCost } = packageData
  const sectionId = `section-${productCode}`
  const buttonId = `accordion-${productCode}`
  const isSelected = productCode === selectedPackage
  const selectedClass = isSelected ? 'selected' : ''

  const handleSelect = (e) => {
    e.preventDefault()
    onClick(productCode)
  }

  const content = isCarefree || productCode === 'CPD004'
    ? children
    : <>
      <div className="product__icon">
        <ProdIcons products={productIconMap[productCode]}/>
      </div>
      <div className="product__title-text">{nameMap[productCode]}</div>
      <div className="package-price">£{monthlyCost}/month</div>
    </>

  return <li>
    <div className="package">
      <button
        className={selectedClass}
        id={buttonId}
        onClick={handleSelect}
        aria-controls={sectionId}
        aria-expanded={isSelected}
      >
        {content}
      </button>
    </div>
  </li>
}

const CarefreeBundleItem = ({
  handleClick,
  individualCost,
  packageData = [],
  selectedPackage
}) => {
  const { monthlyCost: price } = packageData
  const minSavingToNearest5 = calculatePercentageSaving(individualCost, price, 5)

  return <PackageListItem
    onClick={handleClick}
    packageData={packageData}
    isCarefree
    selectedPackage={selectedPackage}
  >
    <div className="package-carefree">
      <div className="product__icon">
        <ProdIcons />
      </div>
      <div><span className="bold">Carefree bundle: </span><span className="less-bold">Warranty + Service + Assistance</span>
      </div>
      <div className="separately">
        <div className="grey">Purchased separately</div>
        <div className="package-price grey">£{individualCost}/month</div>
      </div>
      <div className="bundle-line">
        <div className="bold">Today's bundle price, save over {minSavingToNearest5}%</div>
        <div className="package-price">£{price}/month</div>
      </div>
    </div>
  </PackageListItem>
}

export const ChoosePackage = ({ productName, changeVehicle, data = [], selectDefault = null }) => {
  const product = productName.toLowerCase()

  const associatedData = associatedProductMap[product]
    .map(p => data.find(({ productCode }) => p === productCode))

  const { productCode: storedProductCode = '' } = getLocalStorageItem(dataStorageKeys.selectedProduct) || {}
  const selectedProduct = associatedData.find(({ productCode }) => productCode && productCode === storedProductCode)
  const selectedProductCode = (selectedProduct && selectedProduct['productCode']) || selectDefault

  const [selectedPackage, setSelectedPackage] = React.useState(selectedProductCode)
  const [errorMessage, setErrorMessage] = React.useState(null)

  const vehicleData = getLocalStorageItem(dataStorageKeys.vehicleData)
  const { make = '', model = '', formattedReg = '' } = vehicleData || {}

  const handleVehicleButton = (e) => {
    e.preventDefault()
    changeVehicle(vehicleData)
  }

  const title = product === 'carefree'
    ? 'Your bundle'
    : 'Choose your package'

  const carefreeIndividualCost = calculateSeparateCosts(data)

  const content = product === 'carefree'
    ? <CarefreeBundleItem
      isSelected
      individualCost={carefreeIndividualCost}
      handleClick={setSelectedPackage}
      packageData={associatedData[0]}
      selectedPackage={selectedPackage}
    />
    : associatedData.map((prod, i) => i !== 3
      ? <PackageListItem
        key={`package-list-item-${i}`}
        onClick={setSelectedPackage}
        selectedPackage={selectedPackage}
        packageData={prod}
      />
      : <CarefreeBundleItem
        key={`package-list-item-${i}`}
        individualCost={carefreeIndividualCost}
        handleClick={setSelectedPackage}
        packageData={prod}
        selectedPackage={selectedPackage}
      />
    )

  const handleSubmit = (e) => {
    e.preventDefault()
    if (selectedPackage && typeof window !== 'undefined') {
      const selectedProductData = product === 'carefree'
        ? associatedData[0]
        : associatedData.find(({ productCode: p }) => p === selectedPackage)

      setLocalStorageItem(dataStorageKeys.selectedProduct, selectedProductData)

      window.location.href = '../signup'
    }
    else {
      setErrorMessage(<div className="not-selected-error">Please select a package</div>)
    }
  }

  return <section className="choose-package">
    <h2>
      {title}<br/>
      <ViewChangeVehicle formattedReg={formattedReg} make={make} model={model} clickAction={handleVehicleButton}/>
    </h2>
    <ul className="choose-package-ul">
      {content}
    </ul>
    <ul>
      <li>
        {errorMessage}
        <form onSubmit={handleSubmit}>
          <button type="submit" className="button button-electric-blue">Next: Cover details</button>
        </form>
      </li>
    </ul>
  </section>
}
