/** @jsx jsx */
import { jsx } from "theme-ui"
import { SalesMessage } from "./sales-message"
import { ProductTitle } from "./product-title"
import { ChoosePackage } from "./choose-package"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    warranty: file(name: { eq: "warranty-plan" }) {
      props: childMarkdownRemark {
        body: html,
        bits: frontmatter {
          tandcs {
            relativePath
          }
        }
      }
    }
    service: file(name: { eq: "service-plan" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          tandcs {
            relativePath
          }
          serviceScheduleFull {
            relativePath
          }
        }
      }
    }
    assistance: file(name: { eq: "assistance-plan" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          tandcs {
            relativePath
          }
        }
      }
    }
    carefree: file(name: { eq: "carefree-plan" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          tandcs {
            relativePath
          }
        }
      }
    }
  }
`

export const PageProduct = ({
  product = '',
  content,
  setModalProps,
  quoteData
}) => {
  const productPathSlug = product.toLowerCase()
  const { [productPathSlug]: { props: { 
    body,
    bits: {
      tandcs,
      serviceScheduleFull
    }
   } } } = useStaticQuery(query)
  const title = productPathSlug === 'carefree'
    ? 'Carefree bundle'
    : `${product} plan`
  const baseImagePath = `/assets/site/${productPathSlug}`
  const srcSet = [
    `${baseImagePath}-lg.png`,
    `${baseImagePath}-md.png`,
    `${baseImagePath}-sm.png`
  ]

  const kickOffQuote = (data) => {
    setModalProps({
      defaultReg: data.reg,
      defaultMileage: data.mileage,
      isActive: true,
      defaultStep: 1
    })
  }

  const changeQuote = (data) => {
    setModalProps({
      defaultData: data,
      defaultReg: data.reg,
      defaultMileage: data.mileage,
      isActive: true,
      defaultStep: 1
    })
  }

  const formOrData = quoteData && quoteData.length
    ? <ChoosePackage
      productName={product}
      changeVehicle={changeQuote}
      selectDefault={product === 'Carefree' ? 'carefree' : null}
      data={quoteData}
    />
    : <div>
      <hr/>
      <SalesMessage altTitle="Purchase your cover" action={kickOffQuote}/>
    </div>

    const displayServiceScheduelsCss = product === 'Service' 
    ? 
    <a
    href={`/assets/${serviceScheduleFull.relativePath}`}
    className="document-link"
    target="_blank"
    rel="noreferrer"
    >
      <button className="button learn download-pdf service-pdf-button" title="Download Service Schedules PDF">
        View Service Schedules
      </button>
    </a>
    : ''

  return <section className="page-product">
    <div className="page-product__image">
      <picture>
        <source media="(min-width: 1022px)" srcSet={srcSet[0]}/>
        <source media="(min-width: 576px)" srcSet={srcSet[1]}/>
        <img src={srcSet[2]} alt={product}/>
      </picture>
      <a
        href={`/assets/${tandcs.relativePath}`}
        className="document-link"
        target="_blank"
        rel="noreferrer"
      >
        <button className="button learn download-pdf" title="Download PDF" >
            View full T&Cs
        </button>
      </a>
      {displayServiceScheduelsCss}
    </div>
    <div>
      <h1>
        <ProductTitle title={title} icon={productPathSlug} />
      </h1>
      <div className="page-product__content">
        <div dangerouslySetInnerHTML={{ __html: content }}/>
        <div dangerouslySetInnerHTML={{ __html: body }}/>
        {formOrData}
      </div>
    </div>
  </section>
}
