import Seo from "./seo"
import { PageProduct } from "./page-product"
import { WhatsIncluded } from "./whats-included"
import { Feature } from "./feature"
import { Faq } from "./faq"
import Layout from "./layout"
import React from "react"
import { QuoteProcess, initialModalPropsState } from "./quote-process"
import { dataStorageKeys, getLocalStorageItem } from "../util/local-storage-wrapper"

export const ProductPageContent = ({ pageName, title, excerpt, html }) => {
  const [modalProps, setModalProps] = React.useState(initialModalPropsState)
  const [vehicleData, setVehicleData] = React.useState(null)
  const [quoteData, setQuoteData] = React.useState(null)

  React.useEffect(() => {
    if (!quoteData) {
      setQuoteData(getLocalStorageItem(dataStorageKeys.quoteData))
    }
  }, [quoteData, setQuoteData])

  React.useEffect(() => {
    if (!vehicleData) {
      setVehicleData(getLocalStorageItem(dataStorageKeys.vehicleData))
    }
  }, [setVehicleData, vehicleData])

  const closeModal = () => {
    setQuoteData(getLocalStorageItem(dataStorageKeys.quoteData))
    setVehicleData(getLocalStorageItem(dataStorageKeys.vehicleData))
    setModalProps(initialModalPropsState)
  }

  const initiateQuote = () => {
    setModalProps({ ...initialModalPropsState, isActive: true })
  }

  return <Layout className="product-page">
    <Seo title={title} description={excerpt} />
    {modalProps.isActive ? <QuoteProcess {...modalProps} closeModal={closeModal}/> : null}
    <div className="wrapper">
      <PageProduct product={title} content={html} setModalProps={setModalProps} quoteData={quoteData}/>
      <WhatsIncluded pageName={pageName}/>
      {
        pageName !== "carefree"
          ? <Feature exclude={pageName}  quoteData={quoteData} initiateQuote={initiateQuote}/>
          : null
      }
      <Faq thisPage={pageName} />
    </div>
  </Layout>
}
