import React from "react"
import { graphql } from "gatsby"
import { ProductPageContent } from "../components/product-page-content"

export const pageQuery = graphql`
  query ServiceQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 140)
      frontmatter {
        title
      }
    }
  }
`
const ServicePage = ({ data }) => {
  const {
    markdownRemark: { frontmatter: { title }, html, excerpt }
  } = data

  return <ProductPageContent pageName="service" title={title} excerpt={excerpt} html={html}/>
}

export default ServicePage
